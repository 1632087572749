<template>
    <div class="action-schedule-reservation-agent">
        <div class="title f-bold red" v-html="`약속이 임박했어요. 장소를 입력해주세요.`" />
        <div class="body" v-html="body"></div>

        <ScheduleProfile
            :user="getUser(content)"
            :date="content.date_confirmed"
            :place="content.meet_place"
            :food="foodPrefer"
            class="meeting-info"
            :meetType="meetType"
        />
        <button class="btn btn-black btn-block" @click="openModalMeetingPlaceInput">장소 입력하기</button>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionScheduleReservationAgent',
    props: ['message'],
    components: {},
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.name,
                    birthday: person.birthday,
                }
            }
        },
        body() {
            return '약속 장소를 입력해주세요. 회원이 직접 예약을 하기로 했는데 입력을 안했다면, 회원과 연락을 취해주세요. <br/> 만약에 회원과 연락이 닿지 않는다면 임의로 예약을 진행하시고, 메시지를 남겨놔주세요. '
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        meetType() {
            const content = [
                {
                    id: 1,
                    name: 'meal',
                    title: '식사',
                    selected: false,
                },
                {
                    id: 2,
                    name: 'coffee',
                    title: '커피',
                    selected: false,
                },
                {
                    id: 3,
                    name: 'both',
                    title: '식사 또는 커피',
                    selected: false,
                },
            ]
            return content.find(c => c.name === this.content.meet_type_confirmed).title
        },
    },
    methods: {
        async openModalMeetingPlaceInput() {
            try {
                const res = await this.$modal.custom({
                    component: 'ModalInput',
                    options: {
                        title: '장소 입력하기',
                        inputs: [
                            {
                                type: 'input',
                                key: 'map',
                                placeholder: '네이버 지도 주소를 입력해주세요.',
                            },
                        ],
                    },
                })
                if (res.length) {
                    const map = res.find(a => a.key === 'map')
                    const data = await scheduleService.addReservation({
                        url: map.text,
                        schedule_id: this.content.schedule_id,
                    })
                    // console.log(data)
                }
            } catch (e) {
                // console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.action-schedule-reservation-agent {
    .title {
        padding: 0;
    }
    .body {
        padding: 12px 0 0;
    }
    width: 100%;
    padding: 16px;
    .meeting-info {
        width: 100%;
    }
}
</style>
